import type {
  BooleanData,
  Component,
  ContentChunkData,
  DatetimeData,
  ImagesData,
  ItemRelationsData,
  NumericData,
  ParagraphCollectionData,
  PieceData,
  RichTextData,
  SelectionData,
  SingleLineData,
} from '~/utils/crystallize/component-schemas'

export type RawContent =
  | SingleLineData
  | BooleanData
  | RichTextData
  | NumericData
  | SelectionData
  | DatetimeData
  | ImagesData
  | ItemRelationsData
  | ContentChunkData
  | PieceData
  | ParagraphCollectionData
  | { [key: string]: RawContent }
  | Array<RawContent | undefined>
  | null

export type NormalizedComponents = Record<string, RawContent>

/**
 * Normalize a component's content by unwrapping it based on its type.
 *
 * @param component - Content part of a raw Crystallize component
 */
export function getContent(component: Component): RawContent | undefined {
  switch (component.type) {
    case 'singleLine':
      return component.content?.text
    case 'boolean':
      return component.content?.value
    case 'richText':
      return component.content?.json ?? []
    case 'numeric':
      return component.content?.number
    case 'selection':
      return component.content?.options ?? []
    case 'datetime':
      return component.content?.datetime
    case 'images':
      return component.content?.images ?? []
    case 'paragraphCollection':
      return component.content?.paragraphs ?? []
    case 'contentChunk':
      return (component.content?.chunks ?? []).map(transformComponents)
    case 'itemRelations':
      return component.content?.items ?? component.content?.productVariants ?? []
    case 'piece':
      return transformComponents(component.content?.components ?? [])
    default:
      return null
  }
}

/**
 * Transform an array of components to an object keyed by the components' IDs and with their contents normalized.
 *
 * The keys are also converted from kebab-case to camelCase for simplified lookup.
 *
 * @param components - Array of raw Crystallize components
 */
export function transformComponents(components: Component[]) {
  return components.reduce<NormalizedComponents>((acc, component) => ({
    ...acc,
    [toCamelCase(component.id)]: getContent(component) ?? null,
  }), {})
}
