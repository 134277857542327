import { array, intersect, literal, nullish, object, union } from 'valibot'
import { baseCatalogueSchema } from '~/utils/crystallize/catalogue'
import {
  genericComponents,
  relatedProductVariantsData,
  richTextData,
  singleLineData,
} from '~/utils/crystallize/component-schemas'
import { documentSchema } from '~/utils/crystallize/document'
import { productSchema } from '~/utils/crystallize/product'

const childSchema = union([
  productSchema,
  documentSchema,
])

/**
 * Schema for Crystallize folders.
 *
 * @see https://crystallize.com/learn/concepts/pim/folder
 */
const baseFolderSchema = object({
  type: literal('folder'),
  children: array(childSchema), // TODO: If we at some point have nested folders, this must me made recursive.
  components: intersect([
    genericComponents,
    // TODO: This is not strictly true for all folders, only for the produktkatalog type, but works for now. At some point we should deduce this shape based on the folder's shape identifier.
    object({
      title: nullish(singleLineData),
      description: nullish(richTextData),
      highlighted: nullish(relatedProductVariantsData),
    }),
  ]),
})

export const folderSchema = intersect([
  baseCatalogueSchema,
  baseFolderSchema,
])
