/**
 * Check if a value is non-nullable, i.e. not null or undefined.
 *
 * Narrows the type, which makes it useful in filter functions or type guards.
 *
 * @param value - The value to check, can be anything
 */
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

/**
 * Check if a value is truthy, i.e. not false, '', 0, null or undefined.
 *
 * Narrows the type, which makes it useful in filter functions or type guards.
 *
 * @param value - The value to check, can be anything
 */
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value
}

/**
 * Check if a value is distinct in an array. Can be used in a filter function to remove duplicates.
 *
 * @param value - The value to check
 * @param index - The index of the value in the array
 * @param array - The array to check
 */
export function distinct<T>(value: T, index: number, array: T[]): boolean {
  return array.indexOf(value) === index
}

/**
 * Check if a value is truthy and distinct in an array. Can be used in a filter function to remove duplicates and falsy values
 *
 * @param value - The value to check
 * @param index - The index of the value in the array
 * @param array - The array to check
 */
export function truthyAndDistinct<T>(
  value: T,
  index: number,
  array: T[],
): value is Truthy<T> {
  return truthy(value) && distinct(value, index, array)
}
