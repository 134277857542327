/*
Contains schemas for most types of Crystallize components. They should stay in the same file, as some of them are recursive and reference each other. By putting them into separate JS modules, we would have circular imports, which might break.
 */
import type { BaseIssue, BaseSchema, InferOutput, IntersectSchema } from 'valibot'
import { array, boolean, date, intersect, lazy, literal, never, nullish, number, object, picklist, pipe, string, transform, union, unknown, url } from 'valibot'
import { transformComponents } from '~/utils/crystallize/component-utils'

/**
 * Boolean/Switch
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#switch-component
 */
export const booleanData = boolean()

export type BooleanData = InferOutput<typeof booleanData>

export const booleanContent = object({ value: booleanData })

export const booleanComponent = object({
  type: literal('boolean'),
  content: nullish(booleanContent),
})

/**
 * Numeric
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#numeric-content
 */
export const numericData = number()

export type NumericData = InferOutput<typeof numericData>

export const numericContent = object({ number: numericData })

export const numericComponent = object({
  type: literal('numeric'),
  content: nullish(numericContent),
})

/**
 * SingleLine
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#single-line
 */
export const singleLineData = string()

export type SingleLineData = InferOutput<typeof singleLineData>

export const singleLineContent = object({ text: singleLineData })

export const singleLineComponent = object({
  type: literal('singleLine'),
  content: nullish(singleLineContent),
})

/**
 * Date
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#date
 */
export const datetimeData = pipe(
  // From Crystallize, this is always a string, but since this schema contains an internal transform to a Date, we support dates and numbers as inputs as well to make parsing idempotent.
  union([
    string(),
    date(),
    number(),
  ]),
  transform(it => new Date(it)),
)

export type DatetimeData = InferOutput<typeof datetimeData>

export const datetimeContent = object({
  datetime: datetimeData,
})

export const datetimeComponent = object({
  type: literal('datetime'),
  content: nullish(datetimeContent),
})

/**
 * Selection
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#selection-component
 */
export const selectionData = array(object({
  key: string(),
  value: string(),
}))

export type SelectionData = InferOutput<typeof selectionData>

export const selectionContent = object({
  options: selectionData,
})

export const selectionComponent = object({
  type: literal('selection'),
  content: nullish(selectionContent),
})

/**
 * Images
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#images
 */
export const image = object({
  url: pipe(string(), url()),
  altText: nullish(string()),
  key: nullish(string()),
  caption: nullish(string()),
  variants: nullish(
    array(object({
      url: pipe(string(), url()),
      width: number(),
      key: nullish(string()),
    })),
  ),
})

export type CrystallizeImage = InferOutput<typeof image>

export type CrystallizeImageVariant = NonNullable<CrystallizeImage['variants']>[number]

export const imagesData = array(image)

export type ImagesData = InferOutput<typeof imagesData>

export const imagesContent = object({
  images: imagesData,
})

export const imagesComponent = object({
  type: literal('images'),
  content: nullish(imagesContent),
})

/**
 * RichText
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#rich-text
 */
const jsonMarkupType = union([
  object({
    metadata: nullish(unknown()),
    type: nullish(
      picklist([
        'paragraph',
        'strong',
        'line-break',
        'unordered-list',
        'ordered-list',
        'list',
        'list-item',
        'quote',
        'preformatted',
        'code',
        'underlined',
        'emphasized',
        'div',
        'span',
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'heading5',
        'heading6',
        'deleted',
        'inserted',
        'subscripted',
        'superscripted',
        'horizontal-line',
        'table',
        'table-row',
        'table-cell',
        'table-head-cell',
      ]),
    ),
  }),
  object({
    type: literal('link'),
    metadata: nullish(object({
      href: nullish(string()),
      target: nullish(string()),
      rel: nullish(string()),
    })),
  }),
])

type JsonMarkupTypeSchema = typeof jsonMarkupType

type JsonMarkupType = InferOutput<JsonMarkupTypeSchema>

// JsonMarkup is recursive, so we need to define the type first, then the schema.
type JsonMarkup = JsonMarkupType & {
  kind: 'block' | 'inline'
  children?: Array<JsonMarkup & JsonMarkupType> | null
  textContent?: string | null
}

type MarkupSchema = IntersectSchema<[JsonMarkupTypeSchema, BaseSchema<unknown, JsonMarkup, BaseIssue<unknown>>], undefined>

const jsonMarkup: MarkupSchema = intersect([
  jsonMarkupType,
  object({
    kind: picklist(['block', 'inline']),
    children: nullish(array(lazy(() => jsonMarkup))),
    textContent: nullish(string()),
  }),
])

export const richTextData = array(jsonMarkup)

export type RichTextData = InferOutput<typeof richTextData>

export const richTextContent = object({ json: array(jsonMarkup) })

export const richTextComponent = object({
  type: literal('richText'),
  content: nullish(richTextContent),
})

/**
 * ParagraphCollection
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#paragraph-collection
 */
export const paragraphCollectionData = array(
  object({
    title: nullish(singleLineContent),
    body: nullish(richTextContent),
    images: nullish(imagesData),
  }),
)

export type ParagraphCollectionData = InferOutput<typeof paragraphCollectionData>

export const paragraphCollectionContent = object({
  paragraphs: paragraphCollectionData,
})

export const paragraphCollectionComponent = object({
  type: literal('paragraphCollection'),
  content: nullish(paragraphCollectionContent),
})

/**
 * ItemRelations
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#item-relation
 */
export const relatedDocumentsData = array(
  object({
    id: string(),
    name: string(),
    path: string(),
  }),
)

export const relatedProductVariantsData = array(
  object({
    sku: string(),
    name: string(),
  }),
)

export type ItemRelationsData = InferOutput<typeof relatedDocumentsData> | InferOutput<typeof relatedProductVariantsData>

export const itemRelationsContent = union([
  object({
    productVariants: nullish(never()),
    items: relatedDocumentsData,
  }),
  object({
    items: nullish(never()),
    productVariants: relatedProductVariantsData,
  }),
])

export const itemRelationsComponent = object({
  type: literal('itemRelations'),
  content: nullish(itemRelationsContent),
})

export const baseComponents = union([
  booleanComponent,
  numericComponent,
  singleLineComponent,
  datetimeComponent,
  selectionComponent,
  imagesComponent,
  richTextComponent,
  paragraphCollectionComponent,
  itemRelationsComponent,
])

export type BaseComponent = InferOutput<typeof baseComponents>

export const idSchema = object({ id: string() })

/**
 * ContentChunk - Created outside the base components, since it references them (content chunk contains instances of other components)
 *
 * @see https://crystallize.com/learn/developer-guides/catalogue-api/fetching-an-item/components#content-chunk-component
 */
export const contentChunkData = array(
  array(
    intersect([
      idSchema,
      union([
        baseComponents,
        // eslint-disable-next-line ts/no-use-before-define
        lazy(() => pieceComponent),
      ]),
    ]),
  ),
)

export type ContentChunkData = InferOutput<typeof contentChunkData>

export const contentChunkContent = object({
  chunks: contentChunkData,
})

export const contentChunkComponent = object({
  type: literal('contentChunk'),
  content: nullish(contentChunkContent),
})

export type ContentChunkComponent = InferOutput<typeof contentChunkComponent>

/**
 * Piece - This is a special, recursive component, meaning it can contain instances of itself. Valibot allows it, but TS struggles. Solution is to create the type first, then the schema.
 *
 * @see https://crystallize.com/learn/concepts/pim/piece
 */
export const pieceData = array(
  intersect([
    idSchema,
    union([
      baseComponents,
      contentChunkComponent,
      // eslint-disable-next-line ts/no-use-before-define
      lazy(() => pieceComponent),
    ]),
  ]),
)

export type PieceData = InferOutput<typeof pieceData>

export interface PieceComponent {
  type: 'piece'
  content?: null | {
    components: Array<{ id: string } & (BaseComponent | PieceComponent | ContentChunkComponent)>
  }
}

export const pieceContent = object({
  components: pieceData,
})

type PieceComponentSchema = BaseSchema<unknown, PieceComponent, BaseIssue<unknown>>

export const pieceComponent: PieceComponentSchema = object({
  type: literal('piece'),
  content: nullish(pieceContent),
})

// General stuff
export const componentContentSchema = union([
  baseComponents,
  contentChunkComponent,
  pieceComponent,
])

export type ComponentContent = InferOutput<typeof componentContentSchema>

export const componentSchema = intersect([
  idSchema,
  componentContentSchema,
])

export type Component = InferOutput<typeof componentSchema>

/**
 * Generic component schema which accepts an array of raw Crystallize components and then transforms them into a more usable format.
 *
 * Intended to be combined with more specific component schemas.
 */
export const genericComponents = pipe(
  array(componentSchema),
  transform(transformComponents),
)
