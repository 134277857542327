import { intersect, literal, object } from 'valibot'
import { baseCatalogueSchema } from '~/utils/crystallize/catalogue'

/**
 * Schema for Crystallize documents. Will differ between the different document types, and should be extended for each use case.
 *
 * @see https://crystallize.com/learn/concepts/pim/document
 */
const baseDocumentSchema = object({
  type: literal('document'),
})

export const documentSchema = intersect([
  baseCatalogueSchema,
  baseDocumentSchema,
])
