import { object, picklist, pipe, string, transform } from 'valibot'
import { genericComponents } from '~/utils/crystallize/component-schemas'

/**
 * Schema for shared Crystallize catalogue properties.
 *
 * @see https://crystallize.com/learn/concepts/pim/catalogue
 */
export const baseCatalogueSchema = object({
  id: string(),
  name: string(),
  path: string(),
  type: picklist([
    'document',
    'folder',
    'product',
  ]),
  shape: pipe(
    object({
      identifier: string(),
    }),
    transform(({ identifier }) => identifier),
  ),
  components: genericComponents,
})
