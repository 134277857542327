import { truthy } from '#shared/filter'

/**
 * Get reactive queryKey + queryFn that will fetch and parse a Crystallize catalogue.
 *
 * @param path - The path to the Crystallize document.
 * @param version - If the composable is not used in a Vue component or directly in another composable, you must provide the version manually.
 */
export function useCatalogueQueryOptions(path: MaybeRef<string>, version?: ReturnType<typeof useCrystallizeVersion>) {
  if (!version) {
    version = useCrystallizeVersion()
  }

  const queryKey = computed(() => {
    const parts = unref(path).split('/').filter(truthy)
    return ['crystallize', version, ...parts]
  })

  return {
    // This complains that we are missing 'version' and 'path' in our queryKey, but we are not (see above).
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: async () => {
      const data = await GqlGetCatalogue({
        version: unref(version),
        path: unref(path),
      })

      return parseCatalogue(data.catalogue)
    },
  }
}
