import type { GetCatalogueQuery } from '#gql'

import type { CrystallizeImageVariant } from '~/utils/crystallize/component-schemas'
import { type InferOutput, parse, union } from 'valibot'
import { documentSchema } from '~/utils/crystallize/document'
import { folderSchema } from '~/utils/crystallize/folder'
import { productSchema } from '~/utils/crystallize/product'

const catalogueSchema = union([
  productSchema,
  folderSchema,
  documentSchema,
])

type RawCatalogue = GetCatalogueQuery['catalogue']

type ParsedCatalogue = InferOutput<typeof catalogueSchema>

/**
 * Parse a Crystallize catalogue, which checks if the document is valid and transforms the components into a more usable format.
 *
 * @param catalogue - The raw document to parse
 */
export function parseCatalogue(catalogue: RawCatalogue): ParsedCatalogue {
  return parse(catalogueSchema, catalogue)
}

// avif first, fallback to webp, png and finally jpg if in a browser without support
const IMAGE_EXTENSION_PRIORITY = ['avif', 'webp', 'png', 'jpg'] as const

/**
 * Get image extension + priority when loading the image.
 *
 * @param url - Raw image url
 */
export function getExtAndPriority({ url }: Pick<CrystallizeImageVariant, 'url'>) {
  const raw = url.split('.').pop()
  const ext = IMAGE_EXTENSION_PRIORITY.find(ext => ext === raw)
  return ext && { ext, priority: IMAGE_EXTENSION_PRIORITY.indexOf(ext) }
}
